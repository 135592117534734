<template>
  <b-card>
    <validation-observer ref="formStudent" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Tanggal Kegiatan"
              label-for="h-date"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Tanggal Kegiatan"
                rules="required"
              >
                <flat-pickr
                  id="h-date"
                  v-model="model.date"
                  placeholder="Tanggal Kegiatan"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  :config="{ mode: 'range' }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Judul Kegiatan"
              label-for="h-title"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Judul Kegiatan"
                rules="required"
              >
                <b-form-input
                  id="h-title"
                  placeholder="Judul Kegiatan"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  v-model="model.title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Tempat Kegiatan"
              label-for="h-place"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Tempat Kegiatan"
                rules="required"
              >
                <b-form-input
                  id="h-place"
                  placeholder="Tempat Kegiatan"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  v-model="model.place"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Detail Kegiatan"
              label-for="h-desc"
              label-cols-md="2"
            >
              <validation-provider
                #default="{ errors }"
                name="Detail Kegiatan"
                rules="required"
              >
                <b-form-textarea
                  id="h-desc"
                  placeholder="Detail Kegiatan"
                  :disabled="config.action === 'show'"
                  :state="errors.length > 0 ? false : null"
                  v-model="model.description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
              @click="$router.go(-1)"
            >
              Kembali
            </b-button>
            <b-button
              v-if="config.action !== 'show'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        action: this.$route.meta.action,
        api: "events",
        redirect: "event",
      },
      model: {},
    };
  },
  created() {
    const _ = this;
    if (_.config.action !== "create") {
      _.loadData();
    }
  },
  methods: {
    loadData() {
      const _ = this;
      _.$axios.get(`${_.config.api}/${_.$route.params.id}`).then((res) => {
        const consume = res.data;
        _.model = consume.data;
        _.model.date = `${consume.data.startDate} to ${consume.data.endDate}`;
      });
    },
    save() {
      const _ = this;

      if (_.config.action === "create") {
        const [startDate, endDate] = _.model.date.split(" to ");
        _.$axios
          .post(`${_.config.api}`, { ..._.model, startDate, endDate: endDate ? endDate : startDate})
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                variant: "success",
                text: "Data Berhasil di Buat",
              },
            });
          });
      } else {
        const [startDate, endDate] = _.model.date.split(" to ");
        delete _.model.date;
        _.$axios
          .put(`${_.config.api}/${_.$route.params.id}`, { ..._.model, startDate, endDate: endDate ? endDate : startDate })
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                variant: "success",
                text: "Data Berhasil di Ubah",
              },
            });
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
