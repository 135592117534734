var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"formStudent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tanggal Kegiatan","label-for":"h-date","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tanggal Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"h-date","placeholder":"Tanggal Kegiatan","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null,"config":{ mode: 'range' }},model:{value:(_vm.model.date),callback:function ($$v) {_vm.$set(_vm.model, "date", $$v)},expression:"model.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Judul Kegiatan","label-for":"h-title","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Judul Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-title","placeholder":"Judul Kegiatan","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tempat Kegiatan","label-for":"h-place","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Tempat Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-place","placeholder":"Tempat Kegiatan","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null},model:{value:(_vm.model.place),callback:function ($$v) {_vm.$set(_vm.model, "place", $$v)},expression:"model.place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Detail Kegiatan","label-for":"h-desc","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Detail Kegiatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"h-desc","placeholder":"Detail Kegiatan","disabled":_vm.config.action === 'show',"state":errors.length > 0 ? false : null,"rows":"3","max-rows":"6"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Kembali ")]),(_vm.config.action !== 'show')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]):_vm._e()],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }